import { SequenceNode } from "../../../../../libs/ts/WorkflowTree/Nodes/SequenceNode";
import { Model } from "../Model";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, IDropdownField, IEditableLabelField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";

const personTitleElements = "model.dataFieldsSource.personTitle"

export class SiteContactDetailsNode extends SequenceNode<JbpTreeNodeData, IJbpChildNodeRelationship> {


    constructor() {
        const validTitles = ["Mx", "Dr", "Mr", "Mrs", "Ms", "Miss", "Prefer not to say"]

        const titleField = {
            fieldType: FieldType.DropDown,
            elementsModelPath: personTitleElements,
            title: "Title",
            placeHolder: "Please select a title",
            modelPath: "model.results.siteContactDetails.title",
            mandatory: true,
            isValid: (value: string) => {
                const passes = validTitles.includes(value);
                if(passes && value != null) return { isValid: true }
                return {
                    isValid: false,
                    errorMessage: "Please ensure you have selected a title"
                }
            }
        } as IDropdownField;

        const firstNameField = {
            fieldType: FieldType.EditableLabelField,
            title: "First Name",
            placeHolder: "Please enter a first name",
            modelPath: "model.results.siteContactDetails.firstname",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes && value != null) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please ensure your first name is valid"
                }
            }
        } as IEditableLabelField;

        const surnameField = {
            fieldType: FieldType.EditableLabelField,
            title: "Surname",
            placeHolder: "Please enter a valid contact surname",
            modelPath: "model.results.siteContactDetails.surname",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^[\w\W]{1,50}$/;
                const passes = regex.test(value);
                if(passes && value != null) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please enter a valid surname"
                }
            }
        } as IEditableLabelField

        const emailField = {
            fieldType: FieldType.EditableLabelField,
            title: "Email",
            placeHolder: "Please enter a valid email",
            modelPath: "model.results.siteContactDetails.email",
            isValid: (value: string) => {
                const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
                const passes = regex.test(value);
                if(passes && value != null) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please ensure your email is valid"
                }
            }
        } as IEditableLabelField

        const contactNumberField = {
            fieldType: FieldType.EditableLabelField,
            title: "Phone Number",
            placeHolder: "Please enter a contact number",
            modelPath: "model.results.siteContactDetails.number",
            mandatory: true,
            isValid: (value: string) => {
                const regex = /^0[12378]\d{8}\d?$/;
                const passes = regex.test(value);
                if(passes && value != null) return { isValid: true }

                return {
                    isValid: false,
                    errorMessage: "Please check your phone number is valid, ensuring no spaces"
                }
            }
        } as IEditableLabelField

        const fetchFn = () => {
            Model.GetModel().Set("model.dataFieldsSource.personTitle", validTitles);
            return Promise.resolve();
        };

        super("siteContactDetails", new JbpTreeNodeData("Please confirm the site contact details", [titleField, firstNameField, surnameField, emailField, contactNumberField], fetchFn));
    }

    public override onInitialise(): void {
        const fetchedFieldMappings = [
            { fetched: "viewModel.fetchedData.siteContactDetails.title", result: "model.results.siteContactDetails.title" },
            { fetched: "viewModel.fetchedData.siteContactDetails.firstname", result: "model.results.siteContactDetails.firstname" },
            { fetched: "viewModel.fetchedData.siteContactDetails.surname", result: "model.results.siteContactDetails.surname" },
            { fetched: "viewModel.fetchedData.siteContactDetails.email", result: "model.results.siteContactDetails.email" },
            { fetched: "viewModel.fetchedData.siteContactDetails.number", result: "model.results.siteContactDetails.number" },
        ]

        fetchedFieldMappings.forEach((mapping) => {
            if(Model.GetModel().Get(mapping.result) == undefined) {
                const fetchedValue = Model.GetModel().Get(mapping.fetched);
                if(fetchedValue != undefined) Model.GetModel().Set(mapping.result, fetchedValue)
            }
        })
    }
}
