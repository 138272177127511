import mongoose from 'mongoose'

const { Schema } = mongoose;

export const schemaDef = new Schema({
    model: {
        siteLoaded: Boolean,
        MPAN: String,
        selectedServiceLine: String,
        selectedContractType: String,
        selectedReasonForCancellation: String,
        selectedSupplierReasonCancellation: String,
        selectedTimeBandForGetOffers: String,
        forceOffersSelection: String,
        selectedTimeSlotValueForGetOffers: String,
        dataFieldsSource: {
            jobTypes: [Object],
            personTitle: [String],
            availableSiteTypeDescriptions: [String],
            availableServiceLines: [String],
            reasonsForCancellation: [String],
            supplierReasonsForCancellation: [String],
            timeBandForGetOffers: [String],
            timeBandSlotsFourHours: [String],
            timeBandSlotsTwoHours: [String],
            forceOffersSelection: [String],
            timeBandOffers2Hours: [Object],
            timeBandOffers4Hours: [Object]
        },
        results: {
            jobType: String,
            jobTypeCategoryTitle: String,
            emailForRams: String,
            siteContactDetails: {
                title: String,
                firstname: String,
                surname: String,
                email: String,
                number: String,
            },
            warrantOfficerDetails: {
                name: String,
                contactNumber: String
            },
            siteAddressDetails: {
                siteName: String,
                line1: String,
                line2: String,
                line3: String,
                town: String,
                county: String,
                postCode: String
            },
            siteTypeCorrect: Boolean,
            updatedSiteType: String,
            powerDown: Boolean,
            liftOnSite: Boolean,
            siteInductionNeeded: Boolean,
            parkingAvailable: Boolean,
            accessInstructions: String,
            whatThreeWordsReference: String,
            parkingInformation: [String],
            meterAccessible: Boolean,
            ramsRequired: Boolean,
            confirmationEmailRequired: Boolean,
            confirmationEmail: String,
            confirmationSMSRequired: Boolean,
            confirmationSMSNumber: String,
            reminderEmailRequired: Boolean,
            reminderEmail: String,
            reminderSMSRequired: Boolean,
            reminderSMSNumber: String,
            jobComments: String,
            offerTokenSelected: Boolean,
            offerSelected: {
                scheduledDate: String,
                offerToken: String,
                spJobCode: String,
                dayOfWeek: String,
                apptBand: String,
                apptDuration: String,
                startTime: String,
                endTime: String
            }
        },
        jobCommentPrependedMessages: {
            liftOnSite: String,
            accessInstructions: String,
            whatThreeWordsReference: String,
            parkingInfo: String,
            customerAgreesToPowerDown: String,
            siteInductionNotRequired: String,
            warrantOfficerName: String,
            warrantOfficerContactNumber: String
        }
    },
    viewModel: {
        wcRouteChosen: Boolean,
        isWCJob: Boolean,
        sequenceVisited: Boolean,
        voidNodeModel: Boolean,
        selectedJobKind: String,
        availableSiteTypeCodes: [{
            siteTypeCode: String,
            siteTypeDescription: String
        }],
        updatedSiteType: String,
        fetchedData: {
            siteAddress: {
                siteName: String,
                line1: String,
                line2: String,
                line3: String,
                line4: String,
                line5: String,
                line6: String,
                town: String,
                county: String,
                postCode: String
            },
            siteContactDetails: {
                title: String,
                firstname: String,
                surname: String,
                email: String,
                number: String,
            },
            siteData: {
                contractType: Number,
                siteType: String
            },
            appointment: {
                startDate: String,
                endDate: String
            },
            MPID: String,
            UserTypeForCurrentUser: String,
            UserRoleForCurrentUser: String,
            ValidMPIDSForCurrentUser: [String]
        },
    },
    updateJobModel: {
        siteAddress: {
            siteName: String,
            line1: String,
            line2: String,
            line3: String,
            line4: String,
            line5: String,
            line6: String,
            town: String,
            county: String,
            postCode: String
        },
        siteContactDetails: {
            title: String,
            firstname: String,
            surname: String,
            email: String,
            number: String,
        },
        jobComments: String
    }
});

export class Model {
    private static model: Model | undefined;

    private schema: mongoose.Schema
    private instance: any;
    private subscribers: Map<string, ((val: string) => void)[]> = new Map();

    private constructor() {
        this.schema = schemaDef;
        const model = mongoose.model('model', this.schema);
        this.instance = new model();
        this.instance.viewModel = {}
    }

    public static GetModel(): Model {
        if(Model.model == undefined) {
            Model.model = new Model();
        }

        return Model.model;
    }

    private typesAreCorrect(data: any, expectedType: string): boolean {
        if(Array.isArray(data) && expectedType == 'array') {
            return true;
        }
        else if(typeof data == 'object' && expectedType == 'object') {
            return true;
        }
        return typeof data == expectedType;
    }

    public Set(modelPath: string, val: any) {
        if(this.ExistsOnModel(modelPath)) {
            if(this.typesAreCorrect(val, this.schema.path(modelPath).instance.toLowerCase())) {
                this.instance[modelPath] = val
                this.notify(modelPath, val)
            }
            else {
                throw `Cannot set a value of type ${typeof val} on model value ${modelPath}. The required type is ${this.schema.path(modelPath).instance}`
            }
        }
        else {
            throw `Path ${modelPath} was not found in the model`
        }
    }

    public ClearModelValue(modelPath: string) {
        if(this.ExistsOnModel(modelPath)) {
            this.instance[modelPath] = undefined
            this.notify(modelPath, undefined)
        }
        else {
            throw `Path ${modelPath} was not found in the model`
        }
    }

    public Get(path: string) {
        if(this.ExistsOnModel(path)) {
            return this.instance[path]
        }
        else {
            throw `Path ${path} was not found in the model`
        }
    }

    public ExistsOnModel(modelPath: string) {
        return Object.keys(this.schema.paths).indexOf(modelPath) !== -1;
    }

    private subscribe(path: string, callback: (value: any) => void) {
        if(this.subscribers.has(path)) {
            this.subscribers.get(path)?.push(callback)
        }
    }

    public Subscribe(path: string, callback: (value: any) => void) {
        this.subscribe(path, callback)
    }

    private notify(modelPath: string, val: any) {
        if(this.subscribers.has(modelPath)) {
            this.subscribers.get(modelPath)?.forEach((callback) => callback(val))
        }
    }

    public teardownModel() {
        Model.model = undefined;
    }
}
