import { Button } from "../components/Button";
import { Modal } from "../components/Modal";
import { Dropdown } from "../components/Dropdown";
import { Typography } from "../components/Typography";
import { siteTypeCodeData, useSiteTypes } from "../hooks/useSiteTypes";
import { useNavigate } from "react-router-dom";
import { Model } from "../Model";
import { useState } from "react";

export interface props {
    mpan: string,
    serviceLine: string,
    closeModalFunction: any
}

export const SiteTypeUpdateModal = (props: props) => {
    const navigate = useNavigate();
    const getSiteTypes = useSiteTypes();

    const rootNodeAddress = `/bookJob/siteJobType/${props.mpan}/${props.serviceLine}`;
    const siteTypes: siteTypeCodeData[] = getSiteTypes(props.serviceLine as "HH" | "NHH" | "SUBMETER");

    const [errorMissingSiteType, setErrorMissingSiteType] = useState(false);

    const handleSiteTypeConfirmation = () => {
        const siteType = Model.GetModel().Get("viewModel.updatedSiteType");
        if(siteType != "" && siteType != undefined) {
            navigate(rootNodeAddress);
        }
        setErrorMissingSiteType(true);
    }

    const handleSiteTypeDropdownSelect = (index: number, siteTypeDescription: string) => {
        const getSelectedCode = (siteTypeDescription: string) => {
            let selectedCode = "";
            siteTypes.forEach((code) => {
                if(siteTypeDescription == code.siteTypeDescription) {
                    selectedCode = code.siteTypeCode;
                }
            })
            return selectedCode;
        }

        const selectedCode = getSelectedCode(siteTypeDescription);
        const isWCSiteType = selectedCode.indexOf("WC") != -1;

        Model.GetModel().Set("model.results.updatedSiteType", selectedCode);
        Model.GetModel().Set("viewModel.isWCJob", isWCSiteType);

        return { isValid: true, errorMessage: "" };
    }

    const getInitialSiteType = () => {
        const getSelectedDescription = (siteTypeCode: string) => {
            let selectedDescription = "";
            siteTypes.forEach((code) => {
                if(siteTypeCode == code.siteTypeCode) {
                    selectedDescription = code.siteTypeDescription;
                }
            })
            return selectedDescription;
        }
        const siteType = getSelectedDescription(Model.GetModel().Get("viewModel.fetchedData.siteData.siteType"));
        return siteType;
    }

    const modalTitle = "Site Type Confirmation";

    return (
        <Modal title={modalTitle} className="w-1/3">
            <Typography content={`Before proceeding with the booking please confirm the site type from the available options`} className="mb-3" />
            <Dropdown title="Site Type" mandatory={true} placeholder="Please confirm the site type for this booking" elementsDataSource={""} initialValue={getInitialSiteType()} elementsForDisplay={siteTypes.map((siteType) => siteType.siteTypeDescription)} onSelect={handleSiteTypeDropdownSelect} modelPath={"viewModel.updatedSiteType"} />
            {errorMissingSiteType ? <Typography content="Please select a site type before proceeding" className="text-feedback-red" /> : <span></span>}
            <div className="float-right">
                <Button primary={false} text="Cancel" className="mr-4" disabled={false} onClick={() => { props.closeModalFunction(false) }} />
                <Button primary={false} text="Ok" disabled={false} onClick={handleSiteTypeConfirmation} />
            </div>
        </Modal>
    )
}
