import { UserInfoResponse, getSiteSuccessType } from "../../../APIDocs/apiTypes";
import { Logger } from "../Logger";
import { Model } from "../Model";
import { useModel } from "./useModel";
import { useSynchronousState } from "./useSynchronousState";
import { useNavigate } from "react-router-dom";
import { useIsEnv } from "./useIsEnv";
import { EnvironmentVariables } from "../EnvironmentVariables";
import { getSupplierIdForHeader } from "./useJbpQuery";
import { Headers } from "cross-fetch";
import { Observable, ObservableEvents } from "../Observable";

const setSiteContactDetails = (data: getSiteSuccessType, model: Model) => {
    if(data.SiteContactDetails?.Title != undefined) {
        model.Set('viewModel.fetchedData.siteContactDetails.title', data.SiteContactDetails?.Title);
    }
    if(data.SiteContactDetails?.ContactFirstName != undefined) {
        model.Set('viewModel.fetchedData.siteContactDetails.firstname', data.SiteContactDetails?.ContactFirstName);
    }
    if(data.SiteContactDetails?.ContactSurname != undefined) {
        model.Set('viewModel.fetchedData.siteContactDetails.surname', data.SiteContactDetails?.ContactSurname);
    }
    if(data.SiteContactDetails?.EmailAddress != undefined) {
        model.Set('viewModel.fetchedData.siteContactDetails.email', data.SiteContactDetails?.EmailAddress);
    }
    if(data.SiteContactDetails?.ContactNumber != undefined) {
        model.Set('viewModel.fetchedData.siteContactDetails.number', data.SiteContactDetails?.ContactNumber);
    }
}

const setSiteDetails = (data: getSiteSuccessType, model: Model) => {
    if(data.SiteAddress.AddressName != undefined) {
        model.Set("viewModel.fetchedData.siteAddress.siteName", data.SiteAddress.AddressName);
    }
    if(data.SiteAddress.AddressLine1 != undefined) {
        model.Set("viewModel.fetchedData.siteAddress.line1", data.SiteAddress.AddressLine1);
    }
    if(data.SiteAddress.AddressLine2 != undefined) {
        model.Set("viewModel.fetchedData.siteAddress.line2", data.SiteAddress.AddressLine2);
    }
    if(data.SiteAddress.AddressLine3 != undefined) {
        model.Set("viewModel.fetchedData.siteAddress.line3", data.SiteAddress.AddressLine3);
    }
    if(data.SiteAddress.AddressLine4 != undefined) {
        model.Set("viewModel.fetchedData.siteAddress.line4", data.SiteAddress.AddressLine4);
    }
    if(data.SiteAddress.AddressLine5 != undefined) {
        model.Set("viewModel.fetchedData.siteAddress.line5", data.SiteAddress.AddressLine5);
    }
    if(data.SiteAddress.AddressLine6 != undefined) {
        model.Set("viewModel.fetchedData.siteAddress.line6", data.SiteAddress.AddressLine6);
    }
    if(data.SiteAddress.Town != undefined) {
        model.Set("viewModel.fetchedData.siteAddress.town", data.SiteAddress.Town);
    }
    if(data.SiteAddress.County != undefined) {
        model.Set("viewModel.fetchedData.siteAddress.county", data.SiteAddress.County);
    }
    if(data.SiteAddress.PostCode != undefined) {
        model.Set("viewModel.fetchedData.siteAddress.postCode", data.SiteAddress.PostCode);
    }
}

const updateUserInfoDetails = (userInfoResponseData: UserInfoResponse | undefined, model: Model) => {
    const userTypeModelPath = "viewModel.fetchedData.UserTypeForCurrentUser";
    const validMPIDSForCurrentUserPath = "viewModel.fetchedData.ValidMPIDSForCurrentUser";
    const userRoleModelPath = "viewModel.fetchedData.UserRoleForCurrentUser";

    model.Set(userTypeModelPath, userInfoResponseData?.userType);
    model.Set(validMPIDSForCurrentUserPath, userInfoResponseData?.mpid);
    model.Set(userRoleModelPath, userInfoResponseData?.role);

    Observable.GetObservable().emit(ObservableEvents.UserInfoLoaded, userInfoResponseData?.user);
}

const updateSiteDetails = (data: getSiteSuccessType, model: Model, errorReportingFunction: ((value: boolean) => void) | undefined) => {
    setSiteDetails(data, model);

    setSiteContactDetails(data, model);

    if(data.Appointment?.StartDate != undefined) {
        model.Set('viewModel.fetchedData.appointment.startDate', data.Appointment?.StartDate)
    }
    if(data.Appointment?.EndDate != undefined) {
        model.Set('viewModel.fetchedData.appointment.endDate', data.Appointment?.EndDate)
    }
    if(data.MPID != undefined) {
        model.Set('viewModel.fetchedData.MPID', data.MPID)
        sessionStorage.setItem("userMPID", data.MPID);
    }

    if(data.SiteTypeCode != undefined) {
        model.Set('viewModel.fetchedData.siteData.siteType', data.SiteTypeCode)
        model.Set("viewModel.isWCJob", data.SiteTypeCode.indexOf("WC") != -1);
    }
    else {
        Logger.Error(`failed to load site details -> Site Type Code is missing for MPAN`);
        if(errorReportingFunction != undefined) errorReportingFunction(true);
    }
}

const updateRemainingModelDetails = (contractId: string, mpan: string, serviceLine: string, model: Model) => {
    model.Set("model.selectedContractType", contractId);
    model.Set("model.MPAN", mpan);
    model.Set("model.selectedServiceLine", serviceLine)
    model.Set("model.siteLoaded", true);
}

const determineContractId = (contractType: string) => {
    switch(contractType) {
        case "HH":
            return "8";
        case "NHH":
            return "2";
        case "SUBMETER":
            return "4";
    }
    return "";
}

export const useStoreSite = () => {
    let model = useModel();
    const dataStored = useSynchronousState(model.Get("model.siteLoaded") === true);
    const navigate = useNavigate();
    const isProd = useIsEnv().isProd;
    const isAutomation = useIsEnv().isAutomation;
    const isRunningLocally = useIsEnv().isLocalHost;
    const isSitePage = window.location.pathname.startsWith('/site') === true;

    const split = window.location.pathname.lastIndexOf('/')
    const contractType = window.location.pathname.substring(split + 1, window.location.pathname.length);
    const contractId = determineContractId(contractType);

    return {
        storeSite: async (mpan: string, serviceLine: string, data: getSiteSuccessType, setDataErrorOccurred?: (value: boolean) => void) => {
            if(isProd === true && isSitePage === false && dataStored.get() === false) {
                navigate('/404');
                return;
            }

            //returned to/on sitePage, some changes may have happened off the back of job booking.
            //reconstruct the model with new data that has been received from the get site call.
            //DO NOT MOVE - NEEDS TO SIT HIGH ENOUGH IN LOGIC TO ENSURE MODEL IS CLEARED BEFORE MORE BOOKINGS ARE POSSIBLY MADE.
            if(isSitePage) {
                model.teardownModel();
                model = Model.GetModel();
                dataStored.set(false);
            }

            if(dataStored.get() == true) {
                return;
            }

            //re-validate user credentials everytime to ensure we always have an accurate user role/type
            return new Promise<void>((res, rej) => {

                const determineIfMpanShouldBeSent = () => {
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    if(isAutomation || isRunningLocally) {
                        return mpan;
                    }

                    return undefined;
                }

                const path = `${EnvironmentVariables.ApiUrl}/jbp/userInfo`
                const MPID = getSupplierIdForHeader()
                const mpanToUseInQuery: string | undefined = determineIfMpanShouldBeSent();

                console.log(`Querying ${path}`)

                const constructedHeader = new Headers({
                    'Contract-Type': contractType,
                    'MPID': MPID,
                });


                //this is only being used by automation/local running to test out userType/role functionality based on explicitly set MPAN's that return specific user types/roles.
                if(mpanToUseInQuery != null) {
                    constructedHeader.append("MPAN", mpanToUseInQuery);
                }

                fetch(path, {
                    headers: constructedHeader,
                    credentials: 'include'
                })
                    .then((responseData) => responseData.json())
                    .then((userInfoResponseData: UserInfoResponse) => {
                        updateUserInfoDetails(userInfoResponseData, model);
                        if(Object.keys(data).length > 0) {
                            updateSiteDetails(data, model, setDataErrorOccurred);

                        } else if(serviceLine !== "SUBMETER") {
                            Logger.Error(`failed to load site details -> No data found for MPAN = ${mpan}`);
                            if(setDataErrorOccurred != undefined) setDataErrorOccurred(true);
                            rej("MISSING SITE DATA");
                        }
                        updateRemainingModelDetails(contractId, mpan, serviceLine, model);
                        dataStored.set(true);
                        res();
                    })
                    .catch(e => {
                        console.log("Cannot determine user credentials navigating to search component: " + e);
                        navigate('/');
                        return;
                    });
            })

        },
        dataStored: dataStored.get()
    }
}
