import { Link } from "react-router-dom";

export interface props {
    text: string,
    icon?: boolean,
    onClick?: () => void,
    disabled: boolean,
    className?: string,
    link?: string,
    primary: boolean,
    widthStyling?: string
}

export const Button = (props: props) => {

    const handleClick = () => {
        if(props.disabled == false && props.onClick != undefined) {
            props.onClick();
        }
    }

    const icon = () => {
        if(props.icon === true) return <img key="img" src={`/buttonIcon${props.disabled ? "Disabled" : "Active"}.png`} className="inline-block w-5 pb-1 pl-3" />
        return <span></span>
    }

    const getControl = (className: string) => {
        return props.link != undefined && props.disabled == false ?
            <Link className={`${className}`} to={props.link} onClick={handleClick}>
                <div>
                    {props.text}
                    {icon()}
                </div>
            </Link>
            : props.text != undefined && props.text != "" ?
                <div className={`${className} cursor-pointer`} onClick={handleClick}>
                    {props.text}
                    {icon()}
                </div>
                :
                <span></span>
    }

    const disabledClassStyling = props.primary ? "bg-secondary-deep-blue-80 pointer-events-none cursor-not-allowed text-secondary-deep-blue-55" : "border-secondary-deep-blue-55 pointer-events-none cursor-not-allowed text-secondary-deep-blue-55 border-[1px]";
    const activeClassStyling = props.primary ? "bg-interactive-coral hover:bg-solid-bold-green cursor-pointer text-solid-deep-blue" : "border-interactive-coral text-interactive-coral border-[1px] hover:bg-interactive-bold-green-db-12";

    const automationDisabledIdentifierClass = props.disabled ? "jbp-button-disabled" : "jbp-button-enabled";
    const automationPrimarySecondaryIdentifierClass = props.primary ? "jbp-button-primary" : "jbp-button-secondary";

    const widthStyling = props.widthStyling != undefined ? props.widthStyling : "w-fit";

    const elementStyling = props.disabled ? disabledClassStyling : activeClassStyling;

    return getControl(`${props.className} ${elementStyling} inline-block ${widthStyling} font-bold jbp-button pl-7 pr-7 pt-2 pb-2 ${automationDisabledIdentifierClass} ${automationPrimarySecondaryIdentifierClass}`);
}
