import { Title } from "../components/Title"
import { SiteDetail } from "./SiteDetail";
import { MeterDetail } from "./MeterDetail";
import { JobHistory } from "./JobHistory";
import { useState } from "react";
import { useDefinedParams } from "../hooks/useDefinedParams";
import { SiteDetailsLoading } from "./loadingElements/SiteDetailsLoading";
import { MeterDetailsLoading } from "./loadingElements/MeterDetailLoading";
import { getSiteErrorType, getSiteSuccessType } from "../../../APIDocs/apiTypes";
import { useStoreSite } from "../hooks/useStoreSite";
import { RestTypes, useJbpQuery } from "../hooks/useJbpQuery";
import { Logger } from "../Logger";
import { JobHistoryLoading } from "./loadingElements/JobHistoryLoading";

export const Site = () => {

    const [mpan, serviceLine] = useDefinedParams(["mpan", "serviceLine"]);
    const [dataErrorOccurred, setDataErrorOccurred] = useState(false)
    const storeSiteData = useStoreSite();

    const onSiteData = (siteData: getSiteSuccessType) => {
        storeSiteData.storeSite(mpan, serviceLine, siteData, setDataErrorOccurred);
    }

    const onSiteDataError = (err: getSiteErrorType) => {
        Logger.Log(`Data found to not exist -> ${err?.msg}`)
        setDataErrorOccurred(true);
    }

    const { isLoading: siteDataLoading, error: siteError, data: siteData } = useJbpQuery<getSiteSuccessType, getSiteErrorType>(RestTypes.Get, undefined, ['site'], `jbp/${mpan}/site`, true, serviceLine, onSiteData, onSiteDataError)


    const siteDetailsJBP = 'w-1/6 inline-block align-top';
    const meterDetailsJBP = 'inline-block w-1/6';
    const jobHistoryJBP = 'inline-block w-4/6 align-top';

    return (
        <div id="jbp-site-information-screen">
            <div className="ml-10">
                <Title className="mt-7 mb-5" text={`MPAN: ${mpan}`} />
                {siteDataLoading ?
                    <div>
                        <SiteDetailsLoading className={siteDetailsJBP}></SiteDetailsLoading>
                        <MeterDetailsLoading className={meterDetailsJBP}></MeterDetailsLoading>
                        <JobHistoryLoading className={jobHistoryJBP} showTitle={true}></JobHistoryLoading>
                    </div>
                    :
                    <div>
                        <SiteDetail className={siteDetailsJBP} dataErrorOccurred={dataErrorOccurred} data={siteData} error={siteError} />
                        <MeterDetail className={meterDetailsJBP} meters={siteData?.Meters} dataErrorOccurred={dataErrorOccurred} />
                        <JobHistory mpan={mpan} serviceLine={serviceLine} className={jobHistoryJBP} siteData={siteData} dataErrorOccurred={dataErrorOccurred} />
                    </div>
                }
            </div>
        </div>
    )
}
