import { useQuery } from "@tanstack/react-query"
import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { EnvironmentVariables } from "../EnvironmentVariables"

export enum RestTypes {
    Get,
    Put,
    Post,
    Delete,
    Patch
}

function getContractTypeCode(serviceLineStringCode: string) {
    const codes = new Map();
    codes.set("HH", "8");
    codes.set("NHH", "2");
    codes.set("SUBMETER", "4");


    return codes.get(serviceLineStringCode);
}

export const getSupplierIdForHeader = (): string => {

    const siteAssociatedMpid = sessionStorage.getItem("userMPID");
    const userAssociatedMpids = sessionStorage.getItem("listOfMpids");

    if(siteAssociatedMpid == null) {
        let listOfMPIDs: string[] = [];
        if(userAssociatedMpids != null) {
            listOfMPIDs = userAssociatedMpids?.split(',');
        } else {
            listOfMPIDs = [];
        }
        const ValidMPIDSForCurrentUser = listOfMPIDs.join(",");
        return ValidMPIDSForCurrentUser
    } else {
        return siteAssociatedMpid != null ? siteAssociatedMpid : "";
    }
}

export const useJbpQuery = <T, E>(restType: RestTypes, data: any, queryIdentifiers: string[], queryPath: string, enabled: boolean, serviceLine: string, onSuccess?: (data: T) => void, onError?: (err: E) => void) => {
    const basePath = EnvironmentVariables.ApiUrl;
    const restTypeIsMutation = restType == RestTypes.Put || restType == RestTypes.Post || restType == RestTypes.Patch;

    const contractType = getContractTypeCode(serviceLine);
    const mpid = getSupplierIdForHeader();

    //userInfo doesn't require contractType and supplierID
    //TODO: userInfo should really use its own specific query to keep useJbpQuery as clean as possible/generic.
    if(!queryPath.includes("userInfo")) {
        if(contractType == null) {
            throw `Unknown Service Line ${serviceLine}, breaking.`
        }
        if(mpid == null) {
            throw 'Unable to determine the associated supplierID for this user, breaking.'
        }
    }

    const config: AxiosRequestConfig = {
        withCredentials: true,
        headers: {
            'Contract-Type': contractType,
            'MPID': mpid
        }
    }

    const getParameterisedPath = () => {
        const params = new URLSearchParams(data).toString()

        if(params == undefined || params == "") {
            return queryPath;
        }
        else {
            return `${queryPath}?${params}`;
        }
    }

    const body = restTypeIsMutation ? data : undefined;
    const completeQuery = restTypeIsMutation ? queryPath : getParameterisedPath();

    //This can be split to method specific handling in the future if needed
    //For the time being this was all duplicated so moved to this function.
    const handleAxiosErrorResponse = (err: AxiosError) => {
        const redirectUrl: string = EnvironmentVariables.RedirectUrl;
        if(err.response?.status === 401 || err.response?.status === 403) {
            console.log(`Navigating to ${redirectUrl} for expired auth`);
            //.randomUUID generates a v4 version of uuid: https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
            window.location.href = redirectUrl + "&state=" + crypto.randomUUID();
        } else if(err.code == "ERR_BAD_REQUEST" && err.response == undefined) {
            throw { msg: "The server was unable to respond", code: err.status }
        } else {
            throw err.response?.data;
        }
    }

    let query: () => any;
    // This switch was needed due to typing issues. The axios function was previously instantiated as .get but this meant the function params were then wrong for put and post requests who
    // take config as their 3 arg instead of the 2nd arg as .get does. Ideally this should be cleaner as its copy/past at the moment.
    switch(restType) {
        case RestTypes.Get:
            query = () => {
                console.log(`Querying GET: ${completeQuery}`)
                return axios.get<T>(`${basePath}/${completeQuery}`, config)
                    .then((data) => {
                        return data.data
                    })
                    .catch((err: AxiosError) => {
                        handleAxiosErrorResponse(err);
                    })
            }
            break;
        case RestTypes.Put:
            query = () => {
                console.log(`Querying PUT: ${completeQuery}`)
                return axios.put<T>(`${basePath}/${completeQuery}`, body, config)
                    .then((data) => {
                        return data.data
                    })
                    .catch((err: AxiosError) => {
                        handleAxiosErrorResponse(err);
                    })
            }
            break;
        case RestTypes.Post:
            query = () => {
                console.log(`Querying POST: ${completeQuery}`)
                return axios.post<T>(`${basePath}/${completeQuery}`, body, config)
                    .then((data) => {
                        return data.data
                    })
                    .catch((err: AxiosError) => {
                        handleAxiosErrorResponse(err);
                    })
            }
            break;
        case RestTypes.Delete:
            query = () => {
                console.log(`Querying Delete: ${completeQuery}`)
                return axios.delete<T>(`${basePath}/${completeQuery}`, config)
                    .then((data) => {
                        return data.data
                    })
                    .catch((err: AxiosError) => {
                        handleAxiosErrorResponse(err);
                    })
            }
            break;
        case RestTypes.Patch:
            query = () => {
                console.log(`Querying Patch: ${completeQuery}`)
                return axios.patch<T>(`${basePath}/${completeQuery}`, body, config)
                    .then((data) => {
                        return data.data
                    })
                    .catch((err: AxiosError) => {
                        handleAxiosErrorResponse(err);
                    })
            }
            break;
    }




    const codeShouldPreventRetry = (code: number | undefined) => {
        return code == 404;
    }

    const options = {
        retry: restTypeIsMutation ? false : (err: any) => codeShouldPreventRetry(err.code),
        refetchOnMount: restTypeIsMutation ? false : true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: restTypeIsMutation ? false : true,
        onSuccess,
        onError,
        enabled: enabled,
        cacheTime: 0
    }
    return useQuery<T, E>(queryIdentifiers, query, options)
}
