import { BookingRequest, SiteAddress, SiteContactDetails } from "../../../APIDocs/apiTypes";
import { Model } from "../Model";
import { JobCommentsNode } from "../nodes/JobCommentsNode";
import { JobCommentsForWarrantNode } from "../nodes/JobCommentsForWarrantNode";
export class CompletionMessageFactory {

    public static MakeMessage(): BookingRequest {
        const model = Model.GetModel();

        const siteType = model.Get("model.results.siteTypeCorrect") == true ? model.Get("viewModel.fetchedData.siteData.siteType") : model.Get("model.results.updatedSiteType")
        const jobTypeBooked: string = Model.GetModel().Get('viewModel.selectedJobKind');
        const isWarrantJob = jobTypeBooked?.toLowerCase().indexOf("warrant") > -1;
        const isForced = model.Get("model.forceOffersSelection") != undefined ? model.Get("model.forceOffersSelection") : "No";


        let ramsRequired = false;
        let ramsEmail: string | undefined = undefined;

        if(model.Get("model.results.ramsRequired") == true) {
            ramsRequired = true;
            ramsEmail = model.Get("model.results.emailForRams");
        }

        const request: BookingRequest = {
            Forced: isForced,
            OfferToken: model.Get("model.results.offerSelected.offerToken"),
            ScheduledDate: model.Get("model.results.offerSelected.scheduledDate"),
            SpJobCode: model.Get("model.results.offerSelected.spJobCode"),
            StartTime: model.Get("model.results.offerSelected.startTime"),
            EndTime: model.Get("model.results.offerSelected.endTime"),
            SiteType: siteType,
            JobType: model.Get("model.results.jobType"),
            RamsRequired: ramsRequired,
            RamsEmail: ramsEmail,
            SiteAddress: CompletionMessageFactory.getSiteAddressData(model),
            SiteContactDetails: CompletionMessageFactory.getSiteContactDetails(isWarrantJob, model),
            NotificationPreferences: CompletionMessageFactory.getNotificationPreferences(isWarrantJob, model),
            JobComments: CompletionMessageFactory.getJobComments(isWarrantJob),
            SiteDetails: {
                ParkingAvailable: !isWarrantJob ? model.Get("model.results.parkingAvailable") : true,
            },
        };

        return request;
    }

    // the below methods are needed as warrant jobs skip alot of the usual job booking processes so we have to make sure we default/pick up data from the correct places as it wont
    // have been completed by the user when booking the job.
    private static getSiteAddressData = (model: Model): SiteAddress => {
        return {
            AddressName: model.Get("model.results.siteAddressDetails.siteName"),
            AddressLine1: model.Get("model.results.siteAddressDetails.line1"),
            AddressLine2: model.Get("model.results.siteAddressDetails.line2"),
            AddressLine3: model.Get("model.results.siteAddressDetails.line3"),
            Town: model.Get("model.results.siteAddressDetails.town"),
            County: model.Get("model.results.siteAddressDetails.county"),
            PostCode: model.Get("model.results.siteAddressDetails.postCode")
        }
    }


    private static getNotificationPreferences = (isWarrantJob: boolean, model: Model) => {
        if(!isWarrantJob) {
            return {
                SmsReminderRequested: model.Get("model.results.reminderSMSRequired"),
                EmailReminderRequested: model.Get("model.results.reminderEmailRequired"),
                SmsConfirmationRequested: model.Get("model.results.confirmationSMSRequired"),
                EmailConfirmationRequested: model.Get("model.results.confirmationEmailRequired"),
                NotificationSms: model.Get("model.results.confirmationSMSNumber"),
                NotificationEmail: model.Get("model.results.confirmationEmail")
            }
        } else {
            return {
                SmsReminderRequested: false,
                EmailReminderRequested: false,
                SmsConfirmationRequested: false,
                EmailConfirmationRequested: false,
                NotificationSms: undefined,
                NotificationEmail: undefined
            }
        }
    }

    private static getSiteContactDetails = (isWarrantJob: boolean, model: Model): SiteContactDetails => {
        if(!isWarrantJob) {
            return {
                Title: model.Get("model.results.siteContactDetails.title"),
                ContactFirstName: model.Get("model.results.siteContactDetails.firstname"),
                ContactSurname: model.Get("model.results.siteContactDetails.surname"),
                EmailAddress: model.Get("model.results.siteContactDetails.email"),
                ContactNumber: model.Get("model.results.siteContactDetails.number")
            }
        } else {
            return {
                Title: model.Get("viewModel.fetchedData.siteContactDetails.title"),
                ContactFirstName: model.Get("viewModel.fetchedData.siteContactDetails.firstname"),
                ContactSurname: model.Get("viewModel.fetchedData.siteContactDetails.surname"),
                EmailAddress: model.Get("viewModel.fetchedData.siteContactDetails.email"),
                ContactNumber: model.Get("viewModel.fetchedData.siteContactDetails.number")
            }
        }
    }

    private static getJobComments = (isWarrantJob: boolean) => {
        if(!isWarrantJob) {
            return JobCommentsNode.GetJobComments();
        } else {
            return JobCommentsForWarrantNode.GetJobComments();
        }
    }
}
