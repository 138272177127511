import { Button } from "../components/Button"
import { Title } from "../components/Title"
import { Booking, getBookingsErrorType, getBookingsByMpanSuccessType, getSiteSuccessType } from "../../../APIDocs/apiTypes"
import { Tag } from "../components/Tag"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Typography } from "../components/Typography"
import { Modal } from "../components/Modal"
import { feedbackColour, ListItem } from "../components/ListItem"
import { Logger } from "../Logger"
import { List } from "../components/List"
import { RestTypes, useJbpQuery } from "../hooks/useJbpQuery"
import { JobHistoryLoading } from "./loadingElements/JobHistoryLoading";
import { SiteTypeUpdateModal } from "./SiteTypeUpdateModal"

export interface props {
    className?: string,
    mpan: string,
    serviceLine: string,
    dataErrorOccurred: boolean,
    siteData: getSiteSuccessType | undefined
}

export const JobHistory = (props: props) => {

    const now = new Date();
    const todayMinMillis = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).getTime();
    const todayMaxMillis = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 0).getTime();

    const navigate = useNavigate();
    const [bookingDataErrorOccurred, setBookingDataErrorOccurred] = useState(false)
    const [noBookingsFound, setNoBookingsFound] = useState(false)
    const [showSiteTypeConfirmationModal, setShowSiteTypeConfirmationModal] = useState(false);

    const onBookingDataError = (err: getBookingsErrorType) => {
        Logger.Log(`Data found to not exist -> ${err?.msg}`)
        setBookingDataErrorOccurred(true);
    }

    const onGetBookingsSuccess = (data: getBookingsByMpanSuccessType) => {
        if(data.length === 0) {
            setNoBookingsFound(true);
        }
    }

    const { data, isLoading } = useJbpQuery<getBookingsByMpanSuccessType, getBookingsErrorType>(RestTypes.Get, undefined, ['getBookingList'], `jbp/${props.mpan}/booking`, true, props.serviceLine, onGetBookingsSuccess, onBookingDataError)

    const [displayFutureJobs, setDisplayFutureJobs] = useState(false);
    const [displayPastJobs, setDisplayPastJobs] = useState(false);
    const [displayTodaysJobs, setDisplayTodaysJobs] = useState(false);
    const [displayScheduledJobs, setDisplayScheduledJobs] = useState(false);
    const [displayAbortedJobs, setDisplayAbortedJobs] = useState(false);
    const [displayCancelledJobs, setDisplayCancelledJobs] = useState(false);
    const [displayCompletedJobs, setDisplayCompletedJobs] = useState(false);

    const [futureJob, setFutureJob] = useState<Booking | undefined>(undefined);

    const shouldDisplayBooking = (booking: Booking) => {

        const timeBoundFilters = [displayPastJobs, displayTodaysJobs, displayFutureJobs];
        const statusBoundFilters = [displayScheduledJobs, displayCompletedJobs, displayAbortedJobs, displayCancelledJobs];

        const ignoreTimeBoundFilters = timeBoundFilters.every(v => v === false);
        const ignoreStatusFilters = statusBoundFilters.every(v => v === false);

        if(ignoreStatusFilters && ignoreTimeBoundFilters) return true;

        if(ignoreStatusFilters === false) {
            switch(booking.Status) {
                case "Aborted":
                    if(displayAbortedJobs === false) return false;
                    break;
                case "Cancelled":
                    if(displayCancelledJobs === false) return false;
                    break;
                case "Scheduled":
                    if(displayScheduledJobs === false) return false;
                    break;
                case "Completed":
                    if(displayCompletedJobs === false) return false;
                    break;
                default:
                    Logger.Error(`Unexpected job status: ${booking.Status} when processing shouldDisplayBooking`)
            }
        }

        if(booking.StartDate === undefined) {
            return false;
        }

        if(ignoreTimeBoundFilters === false) {
            if(booking.StartDate >= todayMinMillis && booking.StartDate <= todayMaxMillis && displayTodaysJobs === false) {
                return false;
            }
            else if(booking.StartDate < todayMinMillis && displayPastJobs === false) {
                return false;
            }
            else if(booking.StartDate > todayMaxMillis && displayFutureJobs === false) {
                return false;
            }
        }

        return true;
    }

    const getFutureJob = (): Booking | undefined => {
        const futureJobs = data?.map((booking: Booking) => {
            return booking.StartDate != undefined && booking.StartDate > todayMaxMillis && booking.Status === "Scheduled" ? booking : undefined;
        }).filter((booking: Booking | undefined) => booking != undefined)
        return futureJobs != undefined && futureJobs.length > 0 ? futureJobs[0] : undefined;
    }

    const bookJobOnClick = () => {
        const futureJob = getFutureJob();

        if(futureJob != undefined) {
            setFutureJob(futureJob);
        }
        else {
            setShowSiteTypeConfirmationModal(true);
        }
    }

    const scheduledJobWarningCancelClicked = () => {
        setFutureJob(undefined);
    }

    const determineFeedbackColourForBooking = (booking: Booking) => {
        switch(booking.Status) {
            case "Aborted":
                return feedbackColour.red;
            case "Cancelled":
                return feedbackColour.orange;
            case "Completed":
                return feedbackColour.green;
            case "Scheduled":
                return feedbackColour.blue;
            default:
                {
                    const error = `Unexpected job status: ${booking.Status} when processing determineFeedbackColourForBooking`;
                    Logger.Error(error)
                    throw error;
                }
        }
    }

    const onJobClicked = (booking: Booking) => {
        navigate(`/job/${booking.BookingId}/${props.mpan}/${props.serviceLine}`)
    }

    const title = "Job History:"

    const validBookings = data != undefined ? data.filter((booking: Booking) => shouldDisplayBooking(booking)) : [];

    return (
        <div className={`${props.className} pl-5 pr-10`}>
            <Title text={title} className="mt-7 inline-block" />
            <Button primary={true} text="Book Job" className="float-right mt-7" disabled={bookingDataErrorOccurred || isLoading || props.dataErrorOccurred} icon={true} onClick={bookJobOnClick} />
            {isLoading ?
                <div>
                    <JobHistoryLoading className={props.className}></JobHistoryLoading>
                </div>
                :
                !bookingDataErrorOccurred && !isLoading && !noBookingsFound ?
                    <div>
                        <div>
                            <Tag title="Future Jobs" initiallySelected={displayFutureJobs} onClick={(value) => { setDisplayFutureJobs(value) }} />
                            <Tag title="Past Jobs" initiallySelected={displayPastJobs} onClick={(value) => { setDisplayPastJobs(value) }} />
                            <Tag title="Today's Jobs" initiallySelected={displayTodaysJobs} onClick={(value) => { setDisplayTodaysJobs(value) }} />
                            <Tag title="Scheduled" initiallySelected={displayScheduledJobs} onClick={(value) => { setDisplayScheduledJobs(value) }} />
                            <Tag title="Aborted" initiallySelected={displayAbortedJobs} onClick={(value) => { setDisplayAbortedJobs(value) }} />
                            <Tag title="Cancelled" initiallySelected={displayCancelledJobs} onClick={(value) => { setDisplayCancelledJobs(value) }} />
                            <Tag title="Completed" initiallySelected={displayCompletedJobs} onClick={(value) => { setDisplayCompletedJobs(value) }} />
                        </div>

                        <List title={title} data={validBookings} className="h-screen">
                            {
                                validBookings.map<JSX.Element>((booking: Booking, index: number) => {
                                    const feedbackColour = determineFeedbackColourForBooking(booking);
                                    return <ListItem key={index} title="Scheduled Date:" subTitle={booking.ScheduledDate} cells={[`${booking.JobTypeDesc}`]} feedbackColour={feedbackColour} tagText={booking.Status} onClick={() => onJobClicked(booking)} />
                                })
                            }
                        </List>
                    </div>
                    : noBookingsFound ?
                        <span>
                            <Typography content="There is no job history available to view. Once a job has been booked, it will be displayed here" />
                        </span>
                        :
                        <span>
                            <Typography content="An error has occurred fetching the job history for this site. Please contact support" />
                        </span>
            }

            {futureJob != undefined ?
                <Modal title="A booking already exists" className="w-1/3">
                    <Typography content={`Are you sure you wish to book the job? A ${futureJob.JobTypeDesc} booking already exists and is scheduled for:`} className="mb-3" />
                    <Typography content={`${futureJob.ScheduledDate}`} />
                    <div className="float-right">
                        <Button primary={false} text="Cancel" className="mr-4 mt-5" disabled={false} onClick={scheduledJobWarningCancelClicked} />
                        <Button primary={false} text="Ok" disabled={false} onClick={() => { setFutureJob(undefined); setShowSiteTypeConfirmationModal(true) }} />
                    </div>
                </Modal>
                :
                <span></span>
            }

            {showSiteTypeConfirmationModal ?
                <SiteTypeUpdateModal mpan={props.mpan} serviceLine={props.serviceLine} closeModalFunction={setShowSiteTypeConfirmationModal}></SiteTypeUpdateModal>
                :
                <span></span>
            }
        </div>
    )
}
