import { TreeNodeBuilder } from "../../../../../libs/ts/WorkflowTree/Builder/TreeNodeBuilder";
import { ITreeNode } from "../../../../../libs/ts/WorkflowTree/Nodes/ITreeNode";
import { SelectJobTypeNode } from "../nodes/SelectJobTypeNode";
import { JbpTreeNodeData } from "../treeExtensions/JbpTreeNodeData";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { IWorkflowFactory } from "./IWorkflowFactory";
import { InfoNode } from "../../../../../libs/ts/WorkflowTree/Nodes/InfoNode";
import { Model } from "../Model";

export class BookJobSiteJobTypeWorkflowFactory extends IWorkflowFactory {



    constructor(mpan: string, serviceLine: string) {
        super(mpan, serviceLine);
    }

    public async Build(onTreeChange: () => void): Promise<ITreeNode<JbpTreeNodeData, IJbpChildNodeRelationship>> {
        const siteType = Model.GetModel().Get("model.results.updatedSiteType");
        const currentlySelectedSiteTypeInformationNode = new InfoNode<JbpTreeNodeData, IJbpChildNodeRelationship>("siteTypeMessageNode", new JbpTreeNodeData(`The current known site type for this booking is: ${siteType}. If this is not correct please return to the previous screen to correct this before continueing with the booking.`, []));
        const builder = new TreeNodeBuilder<JbpTreeNodeData, IJbpChildNodeRelationship>(onTreeChange);
        const selectJobTypeNode = new SelectJobTypeNode(this.mpan, this.serviceLine);
        builder
            .AddTreeNode(currentlySelectedSiteTypeInformationNode, IWorkflowFactory.voidWorkflowRelationship)
            .AddTreeNode(selectJobTypeNode, IWorkflowFactory.sequenceRelationship);
        const tree = builder.Build();
        await this.finaliseTree(tree);
        return tree;
    }
}
