import { CompleteNode } from "../../../../../libs/ts/WorkflowTree/Nodes/CompleteNode";
import { AvailableJobType } from "../../../APIDocs/apiTypes";
import { EnvironmentVariables } from "../EnvironmentVariables";
import { Observable, ObservableEvents } from "../Observable";
import { IWorkflowFactory } from "../factories/IWorkflowFactory";
import { Model } from "../Model";
import { JbpTreeNodeData, fetchHandler } from "../treeExtensions/JbpTreeNodeData";
import { FieldType, IField, IRadioButtonField, IRadioButtonsField } from "../treeExtensions/FieldInterfaces";
import { IJbpChildNodeRelationship } from "../treeExtensions/IJbpChildNodeRelationship";
import { getSupplierIdForHeader } from "../../src/hooks/useJbpQuery";

const jobTypesModelAddress = "model.dataFieldsSource.jobTypes"
const IsWcJobModelAddress = "viewModel.isWCJob"
class SelectJobTypeTreeNodeData extends JbpTreeNodeData {
    constructor(title: string, fields: IField[], fetchFunction: fetchHandler) {
        super(title, fields, fetchFunction);
    }

    public override UndoDataChanges(): void {
        Model.GetModel().ClearModelValue(IsWcJobModelAddress)
    }
}
export class SelectJobTypeNode extends CompleteNode<JbpTreeNodeData, IJbpChildNodeRelationship> {

    private buttons: { button: IRadioButtonField, enabled: boolean }[] = [];

    private readonly field: IRadioButtonsField

    public static JobIsSelectedAsWC() {
        return Model.GetModel().Get(IsWcJobModelAddress);
    }

    public static onComplete() {
        const currentlySelectedJobKind = Model.GetModel().Get("viewModel.selectedJobKind");

        if(currentlySelectedJobKind == undefined) return;

        const availableJobTypes: AvailableJobType[] = Model.GetModel().Get(jobTypesModelAddress);
        let jobTypeRes;
        let jobTypeCategoryTitle;
        availableJobTypes.forEach((jobType: AvailableJobType) => {
            if(currentlySelectedJobKind === jobType.CategoryTitle) {
                jobTypeRes = jobType.JobTypeCode;
                jobTypeCategoryTitle = jobType.CategoryTitle;
            }
        })

        Model.GetModel().Set('model.results.jobType', jobTypeRes);
        Model.GetModel().Set('model.results.jobTypeCategoryTitle', jobTypeCategoryTitle);
    }

    private updateButtons() {
        const availableJobTypes: AvailableJobType[] = Model.GetModel().Get(jobTypesModelAddress);
        //without this jobtypes will be added and displayed on screen everytime a jobtype is selected.
        this.buttons = [];
        availableJobTypes.forEach((jobType: AvailableJobType) => {
            const newButton = {
                button: {
                    title: jobType.CategoryTitle,
                    description: jobType.CategoryDescription
                } as IRadioButtonField,
                enabled: true
            };
            this.buttons.push(newButton);
        })
    }

    constructor(mpan: string, serviceLine: string) {

        const determineRoute = () => {
            const selectedJobType: string = Model.GetModel().Get('viewModel.selectedJobKind');

            if(selectedJobType?.toLowerCase().indexOf("warrant") > -1) {
                return `/bookJob/warrantSiteInfo/${mpan}/${serviceLine}`
            }
            return `/bookJob/siteInfo/${mpan}/${serviceLine}`
        }

        const relationship = {
            workflowButtonText: "Next",
            relationshipModelPath: IWorkflowFactory.sequenceRelationship.relationshipModelPath,
            modelValueWhenRelationshipIsTrue: IWorkflowFactory.sequenceRelationship.modelValueWhenRelationshipIsTrue,
            onSelected: SelectJobTypeNode.onComplete,
            determinePathOnSelect: determineRoute,
        } as IJbpChildNodeRelationship

        const fetchAvailableJobTypes = () => {
            return new Promise<void>((res, rej) => {
                // This will change via user selection.
                const initialSiteType = Model.GetModel().Get('viewModel.fetchedData.siteData.siteType');
                const updatedSiteType = Model.GetModel().Get('model.results.updatedSiteType');
                let siteType: string = initialSiteType;

                if(updatedSiteType != undefined) {
                    siteType = updatedSiteType;
                }

                const contractType = Model.GetModel().Get('model.selectedContractType');
                const MPAN = Model.GetModel().Get('model.MPAN');
                const path = `${EnvironmentVariables.ApiUrl}/jbp/${MPAN}/jobTypes/${siteType}`


                const MPID = getSupplierIdForHeader()

                console.log(`Querying ${path}`)

                fetch(path, {
                    headers: {
                        "Contract-Type": contractType,
                        "MPID": MPID
                    },
                    credentials: 'include'
                })
                    .then((responseData) => responseData.json())
                    .then((json: AvailableJobType[]) => {
                        Model.GetModel().Set(jobTypesModelAddress, json);
                        res();
                    })
                    .catch(rej);
            })
        }

        Observable.GetObservable().observe(ObservableEvents.SiteTypeUpdated, async () => {
            await fetchAvailableJobTypes();

            this.updateButtons();

            if(this.TreeChanged != undefined) {
                this.TreeChanged();
            }
        })

        const field = {
            fieldType: FieldType.RadioButtons,
            modelPath: "viewModel.selectedJobKind",
            title: "Job Type",
            hideTitle: true,
            mandatory: true,
            isValid: (title: string) => {
                const isValidRes = title == undefined ? false : true

                return { isValid: isValidRes };
            },
            onChange: () => false,
            buttons: []
        } as IRadioButtonsField

        super("selectJobType", new SelectJobTypeTreeNodeData("Select the type of job to book", [field], fetchAvailableJobTypes), relationship);

        this.field = field;
    }

    public override onInitialise(): void {
        this.updateButtons();
        this.field.buttons = this.buttons.filter(b => b.enabled).map(b => b.button);
    }
}
