import { useNavigate } from "react-router";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { Typography } from "../components/Typography";
import { CompletionMessageFactory } from "../factories/CompletionMessageFactory";
import { useDefinedParams } from "../hooks/useDefinedParams";
import { CancelJob } from "./CancelJob";
import { SiteAddress } from "./SiteAddress";
import { SiteContact } from "./SiteContact";
import { Label } from "../components/Label";


export interface props {
    bookingId: number
}

export const BookingSuccess = (props: props) => {

    const [mpan, serviceLine] = useDefinedParams(["mpan", "serviceLine"]);
    const navigate = useNavigate();

    //To Save us constructing our our own data to use for displaying on the review screen
    //we just make use of the .MakeMessage which gives us access to all the data we need to display
    //apart from the bookingId which will be passed into this component.
    const bookingDataToRender = CompletionMessageFactory.MakeMessage();
    const standardPadding = "p-4";
    const bookingId = props.bookingId.toString();


    const routeToSiteScreen = () => {
        navigate(`/site/${mpan}/${serviceLine}`);
    }

    const routeToSearchScreen = () => {
        navigate(`/`);
    }

    return (
        <div id="jbp-job-screen">
            <div className="ml-10 mr-10">
                <Typography content={"Booking Confirmed. Please quote the booking reference below when raising any tickets related to this job."}></Typography>
                <Card render={true} identifier="jobBooked" className={`${standardPadding} mb-4`}>
                    <Label title="Booking Reference" text={bookingId}></Label>
                </Card>
                <div className="flex">
                    <Card render={true} identifier="addressDetails" className={`${standardPadding} mr-4 flex-col`}>
                        <SiteAddress siteAddress={bookingDataToRender.SiteAddress} />
                    </Card>
                    {
                        bookingDataToRender.SiteContactDetails == undefined
                            ?
                            <span></span>
                            :
                            <Card className={`${standardPadding} mr-4 flex-col`} render={true} identifier="siteContactDetails">
                                <SiteContact contactDetails={bookingDataToRender.SiteContactDetails} />
                            </Card>
                    }
                    <div className="flex-col">
                        <Card render={true} identifier="mpan" className={`${standardPadding} mb-4`}>
                            <Label title="MPXN" text={mpan}></Label>
                        </Card>
                        <Card render={true} identifier="apptTime" className={`${standardPadding} mb-4`}>
                            <Label title="Appointment Time" text={bookingDataToRender.ScheduledDate}></Label>
                        </Card>
                        <Card render={true} identifier="jobBooked" className={`${standardPadding} mb-4`}>
                            <Label title="Job Type" text={bookingDataToRender.JobType}></Label>
                        </Card>
                        {
                            <CancelJob bookingId={bookingId} mpan={mpan} serviceLine={serviceLine}></CancelJob>
                        }
                        <Button text="Back to Site Screen" primary={true} onClick={routeToSiteScreen} className="mt-2" widthStyling="w-full" disabled={false} />
                        <Button text="Back to Search Screen" primary={true} onClick={routeToSearchScreen} className="mt-2" widthStyling="w-full" disabled={false} />
                    </div>
                </div>
            </div>
        </div >
    )


}
