import { getBookingErrorType, getBookingSuccessType } from "../../../APIDocs/apiTypes"
import { Title } from "../components/Title";
import { Card } from "../components/Card";
import { SiteAddress } from "./SiteAddress";
import { RestTypes, useJbpQuery } from "../hooks/useJbpQuery";
import { useDefinedParams } from "../hooks/useDefinedParams";
import { GenericLoadingElement } from "../components/GenericLoadingElement";
import { useNavigate } from "react-router-dom";
import { Typography } from "../components/Typography";
import { SiteContact } from "./SiteContact";
import { CancelJob } from "./CancelJob";
import { EditJob } from "./EditJob";
import { useStoreBooking } from "../hooks/useStoreBooking";
import { Observable, ObservableEvents } from "../Observable";

export const Job = () => {

    const [bookingId, mpan, serviceLine] = useDefinedParams(["bookingId", "mpan", "serviceLine"]);

    const navigate = useNavigate();
    const storeBooking = useStoreBooking();

    const onSuccess = (bookingData: getBookingSuccessType) => {
        storeBooking.storeSite(bookingData);
    }

    const { isLoading, data, refetch } = useJbpQuery<getBookingSuccessType, getBookingErrorType>(RestTypes.Get, undefined, ["booking"], `jbp/${mpan}/booking/${bookingId}`, true, serviceLine, onSuccess);


    const standardPadding = "p-4";

    Observable.GetObservable().observe(ObservableEvents.JobEditSuccessful, () => {
        console.log("triggering refetch for new job data.")
        refetch();
    });


    if(isLoading == true) {
        return <GenericLoadingElement count={5} height="h-20" />
    }
    else if(data != undefined) {
        const renderOutcomeForJob = data.VisitOutcome != undefined && data.VisitOutcome.Description != undefined && data.VisitOutcome.Description != '';

        return (
            <div id="jbp-job-screen">
                <div className="ml-10 mr-10">
                    <Title className="mt-7 mb-5" text={`Job: ${data.BookingId}`} />
                    <div className="flex">
                        <Card render={true} identifier="addressDetails" className={`${standardPadding} mr-4 flex-col`}>
                            <SiteAddress siteAddress={data.AddressDetails} />
                        </Card>
                        {
                            data.SiteContactDetails == undefined
                                ?
                                <span></span>
                                :
                                <Card className={`${standardPadding} mr-4 flex-col`} render={true} identifier="siteContactDetails">
                                    <SiteContact contactDetails={data.SiteContactDetails} />
                                </Card>
                        }
                        <div className="flex-col mr-4">
                            {data.ScheduledDate != undefined ?
                                <Card render={true} identifier="apptTime" className={`${standardPadding} mb-4`}>
                                    <Title text="Appointment Time" className="text-xs font-thin text-solid-bold-green" />
                                    <Title text={data.ScheduledDate} className="mt-4 font-bold text-base" />
                                </Card>
                                :
                                <span></span>}
                            <Card render={true} identifier="jobBooked" className={`${standardPadding} mb-4`}>
                                <Title text="Booked Job" className="text-xs font-thin text-solid-bold-green" />
                                <Title text={data.JobTypeDesc} className="mt-4 font-bold text-base" />
                            </Card>
                            <Card className={`${standardPadding} mb-4`} identifier="visitOutcome" render={renderOutcomeForJob}>
                                <Title text="Outcome" className="text-xs font-thin text-solid-bold-green" />
                                <Title text={`${data?.VisitOutcome?.Description}`} className="mt-4 font-bold text-base" />
                            </Card>
                            <Card render={true} identifier="jobComments" className={`${standardPadding} mb-4`}>
                                <Title text="Comments" className="text-xs font-thin text-solid-bold-green" />
                                <Typography content={data.JobComments == undefined ? "No comments found" : data.JobComments} className="mt-4 whitespace-pre-line" />
                            </Card>
                            {
                                data.IsCancellable === true ?
                                    <CancelJob bookingId={bookingId} mpan={mpan} serviceLine={serviceLine}></CancelJob>
                                    :
                                    <span></span>
                            }
                            {
                                data.IsEditable === true ?
                                    <EditJob bookingId={bookingId} mpan={mpan} serviceLine={serviceLine} booking={data}></EditJob>
                                    :
                                    <span></span>
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    }
    else {
        navigate('/404')
        return <span></span>
    }
}
